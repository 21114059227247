import Buscador from '../components/buscador/view/buscador';
import Onboarding from '../components/onboarding/view/onboarding';
import Destacado from '../components/destacado/view/destacado';
import ListaSeo from '../components/listaSeo/view/listaSeo';
import { Productos } from '../components/productos/view';
import { NewsFeed } from '../components/newsFeed/view';
import { Banners } from '../components/banners/view';
import { Publicar } from '../components/publicar/view';

import useWindowSize from 'public/utils/useWindowsSize';
import { asyncServiceLoader } from 'modules';
import { useContext, useState } from 'react';
import { v4 as uuid } from 'uuid';

import { getPersonasLayout, loadLayoutStaticProps } from "layout";
import { UserContext } from 'layout/UserContext';
import PreFooterStatic from 'components/preFooterStatic/view/PreFooterStatic';

export async function getStaticProps() {
	const { setupServices } = (await import('services.setup.server'));
	const {
		getBannerHeader,
		getBuscador,
		getDestacados,
		getBanners,
		getProductos,
		getListaSeo,
		getMenu,
		getMenuUsuario,
		getEstructuraMenuPrincipal,
		urlBase,
		urlBaseApiBlog,
		getDataPreFooterStatic,
	} = setupServices();

	const datosBannerHeader = await getBannerHeader();
	const listaDestacados = await getDestacados();
	const listaBanners = await getBanners();
	const listaProductos = await getProductos();
	const listaNoticias = [];
	const listaSeo = await getListaSeo();
	const dataPreFooterStatic = await getDataPreFooterStatic();
	const listaMenu = await getMenu();
	const opcionesBuscador = await getBuscador();
	const opcionesMenuUsuario = await getMenuUsuario();
	const estructuraMenuPrincipal = await getEstructuraMenuPrincipal();

	const layoutStaticProps = await loadLayoutStaticProps();

	return {
		props: {
			listaDestacados,
			listaBanners,
			listaProductos,
			listaNoticias,
			listaSeo,
			listaMenu,
			opcionesBuscador,
			// Required data for layout/personas
			datosBannerHeader,
			opcionesMenuUsuario,
			estructuraMenuPrincipal,
			urlBase,
			urlBaseApiBlog,
			sectionName: "Home",
			dataPreFooterStatic,
			...layoutStaticProps,
		},
		revalidate: 60,
	};
}

export default function Home(props) {

	const { sessionState } = useContext(UserContext);
	const { listaProductos, listaNoticias, listaSeo, urlBase, urlBaseApiBlog, dataPreFooterStatic } = props;
	const urlBtnBlog = `${(new URL(urlBaseApiBlog).origin)}?o=home`;
	const [sessionIdState] = useState(uuid())
	const getNewsFeed = asyncServiceLoader('getNoticias');
	const searchBuscador = asyncServiceLoader('searchBuscador');
	const searchByCode = asyncServiceLoader('searchByCode');

	const anchoAlto = useWindowSize();
	const isMobile = () => anchoAlto.width <= 1199;
	return (
		<div>
			<Buscador usuario={sessionState.user} tipoBusqueda={props.opcionesBuscador} searchCallback={searchBuscador} searchByCodeCallback={searchByCode} urlBase={urlBase} isMobile={isMobile()}
				sessionId={sessionIdState} />
			<Banners user={sessionState.user} listaBanners={props.listaBanners}></Banners>
			<Destacado {...props} user={sessionState.user} />
			<Onboarding />
			<Productos listaProductos={listaProductos} user={sessionState.user} />
			<NewsFeed listaNoticias={listaNoticias} cardsClickables={true} btnUrl={urlBtnBlog} btnTexto="Ir al Blog" grupoTitulo="Noticias y Tendencias"
				getFeed={() => getNewsFeed(1)} />
			<Publicar url={"/Publicar?o=home"} user={sessionState.user} />
			<PreFooterStatic dataPreFooter={dataPreFooterStatic} isMobile={isMobile()} />
		</div>
	);
}

Home.getLayout = getPersonasLayout;